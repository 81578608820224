/* PresidentCorner.css */

.p-parent-container {
  background-color: rgb(245, 243, 237); /* Slightly softer tone */
}

.p-child-container {
  margin-top: 140px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px; /* Slightly more padding for better spacing */
}

.image-container img {
  max-width: 380px; /* Reduced max width for subtlety */
  width: 100%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Softer, more diffused shadow */
}

.content {
  color: rgb(44, 44, 44); /* Slightly darker for readability */
  padding: 24px; /* More padding for a balanced layout */
  border-radius: 10px; /* Slightly more rounded */
  background-color: #fdfdfd00; /* Added a subtle background for separation */
}

.content p {
  font-size: 1rem;
  line-height: 1.8; /* Increased line-height for improved readability */
}

.content strong {
  font-size: 1.25rem;
}

.content em {
  font-style: italic;
  font-weight: bold;
  color: #8a6d3b; /* A refined, softer gold for emphasis */
  font-size: 15px;
}

.know-btn {
  border: none;
  width: 130px;
  padding: 12px; /* Reduced padding for a sleek look */
  background-color: #ffc400; /* A more muted gold */
  color: rgb(0, 0, 0); /* White text for contrast */
  border-radius: 6px; /* Soft rounded edges for a gentle look */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.know-btn:hover {
  background-color: #ffffff; /* Darken on hover for feedback */
  border: 3px solid gold;
  color: black;
}




@media (max-width: 1024px) {
  .content p {
    font-size: 15px;
    line-height: 1.8; /* Increased line-height for improved readability */
  }
  .content em{
    font-size: larger;
  }
  
}



@media (max-width: 768px) {

  .p-child-container {
    margin-top: 200px;
  }
  
}
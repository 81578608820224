.about-jci-p-container{
    margin-top: 150px;
    margin-bottom: 100px;

}

/* .india-image{

    display: flex;
    align-items: center;
} */

.india-image img{
    height: 450px;
}


.india-text{
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    top: 40px;
}

.india-text .text-start{
    font-size: x-large;
}

.india-text p{
    text-align: justify;
}




@media (max-width: 768px) {

    .india-text .text-start{
        font-size: larger;
    }
    
}
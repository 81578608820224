.features{
    background-color: rgb(248, 240, 230);
    position: relative;
    top: -30px;
    padding: 30px 30px;
    overflow: hidden;
}

.box{
    display: flex;
    gap: 20px;
    align-items: center;
    text-align: left;
}
.feature-icon{
    width: 80px;
    height: 80px;
    background-color: gold;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.box img{
    height: 40px;
    width: 60px;
    object-fit: contain;
}

.box p{
    text-align: justify;
    font-size: 16px;
}

.box .text-item{
    margin-top: 15px;

}


@media (max-width: 1280px) {

    /* .features{
        top: -120px;
    } */
    
}


@media (max-width: 1024px) {

    /* .features{
        top: -80px;
    } */
    
}


@media (max-width: 768px) {

    /* .features{
        top: -160px
    } */
    
}

@media (max-width: 412px) {

    /* .features{
        top: -160px
    } */
    
}


@media (max-width: 395px) {

    /* .features{
        top: -0px;
    } */

    .feature-icon{
        height: 60px;
        width: 60px;
    }


    .box img{
        height: 30px;
        width: 50px;
        object-fit: contain;
    }


    .box h5{
        font-size: 15px;

    }

    .box p{
        font-size: 12px;
    }
    
}
.form-parent{
    padding-top: 130px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 60px;
}

.form-child{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}



/* iframe{
    width: 100%;
    max-width: 800px;
    height: 1000px;
} */
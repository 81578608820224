.parent-tag-container{

    height: 60vh;
    display: flex;
    align-content: center;
    /* background-color: rgb(167, 153, 153); */
    background-color: white
}
.child-tag-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    
}

.child-tag-container h4{
    font-size: 24px;
    font-weight: bold;
}


.child-tag-container p{
    color: rgb(89, 89, 89);
    font-size: 120px;
    line-height: 1.30;
    letter-spacing: -0.03em;
    margin: 0 auto;
    text-align: center;
    color: transparent;
    background: url(./assets/images/slide-three.jpg) ;
    background-position: center;
    background-size: cover;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: 700;
}

@media (max-width: 1402px) {
    .child-tag-container p{
        font-size: 100px;
    }
    .child-tag-container h4{
        font-size: 24px;
    }
    
}

@media (max-width: 1024px) {
    .child-tag-container p{
        font-size: 80px;
    }
    .child-tag-container h4{
        font-size: 24px;
    }
    
}

@media (max-width: 950px) {
    .child-tag-container p{
        font-size: 80px;
        line-height: 1.2;
    }
    .child-tag-container h4{
        font-size: 24px;
    }
    
}

@media (max-width: 425px) {
    .child-tag-container p{
        font-size: 60px;
    }    
}


@media (max-width: 395px) {
    .child-tag-container p{
        font-size: 60px;
    }
    .child-tag-container h4{
        font-size: 24px;
        position: relative;
        top: -30px;
    }
    
}
.gallery-container{
  background-color: #e7e6dd;
  /* background-color: rgb(74, 137, 185); */
}

.gallery-content{
    color: rgb(20, 20, 20);
}
.gallery-content h4{
    font-size: 24px;
}



.gallery-images row{
    display: flex;
}


.gallery-image .row img{
  height: 200px;
  width: 100%;
  object-fit: cover;
}




.viewbtn .btn-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: gold;
    border-radius: 8px; /* Fully rounded corners */
    text-decoration: none;
    color: black; /* Default text color */
    text-align: center;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  }
  
  .viewbtn .btn-link:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: 3px solid gold;
  }




  @media (max-width: 1200px) {

    .gallery-image .row img{
      height: 110px;
      width: 100%;
      object-fit: cover;
    }
    
  }


  @media (max-width: 768px) {

    .gallery-image .row img{
      height: 110px;
      width: 100%;
      object-fit: cover;
    }
    
  }

  @media (max-width: 768px) {

    .gallery-image .row img{
      height: 110px;
      width: 100%;
      object-fit: cover;
    }
    
  }





@media (max-width: 395px) {

    .gallery-content h4{
        font-size: 24px;
    }

    .gallery-image .row img{
      height: 100px;
      width: 100%;
      object-fit: cover;
    }
    
}
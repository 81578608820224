/* Footer Styles */

.footer {
  background-color: #e7e6dd;
}

.footer-logo {
  height: 240px;
  width: auto;
}

.first-row {
  display: flex;
  align-items: center;
}

.footer-breaker {
  border: 0;
  border-top: 3px solid #858585;
}

.icon-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.icon-box p span {
  color: rgb(14, 14, 14);
  font-weight: 700;
  font-size: 1.2em;
}

.footer-icons {
  background-color: #ffffff;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon {
  height: 40px;
}

.follow-section {
  position: relative;
  top: -22px;
}

.follow-section h5 {
  font-size: 20px;
}

.follow-section i{
  font-size: 25px;
}

.quick-section {
  position: relative;
  top: 36px;
  display: flex;
  flex-direction: column;
  align-items: end;

  /* text-align: start; */
  
}


.quick-link{
  text-align: end;
}

a, p, h5 {
  color: #000000;
  text-decoration: none;
}


.bizotext{
  margin-top: -10px;
}

.bizologo {
  height: 50px;
  margin-top: -10px;
  margin-bottom: 20px;
}

/* Responsive Adjustments */

@media (max-width: 1024px) {
  .footer-logo {
    height: 100px;
  }

  .icon-box {
    padding-top: 30px;
  }

  .footer-icons {
    height: 60px;
    width: 60px;
  }

  .footer-icon {
    height: 40px;
  }

  .first-row p {
    font-size: 13px;
  }

  .quick-section {
    top: 70px;
  }
}

@media (max-width: 768px) {
  /* Stack columns for smaller screens */
  .first-row, .icon-box {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo {
    align-self: center;
    height: 200px;
  }

  .icon-box {
    padding-top: 0;
  }

  .footer-icons {
    height: 60px;
    width: 60px;
  }

  .footer-icon {
    height: 40px;
  }

  .first-row p {
    font-size: 13px;
  }

  .follow-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 5px;
  }

  .quick-section{
    top: 20px;
  }
}

@media (max-width: 425px) {
  /* Further adjustments for extra small screens */
  .footer-icons {
    height: 70px;
    width: 70px;
  }


  .icon-box{
    margin-top: 15px;
  }

  .icon-box p{
    margin-top: -12px;
    font-size: 15px;
  }

  .footer-icon {
    height: 35px;
  }

  .icon-box p span {
    font-size: 1em;
  }

  .follow-section{
    top: 10px;
  }

  .quick-section{
    top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .quick-section li a{
    font-size: 15px;
    white-space: nowrap;
  }
  .quick-link{
    text-align: center;
  }

  .quick-link a{
    text-align: center;
  }

  .bizotext{
    font-size: small;
    margin-top: -10px;
  }
}

.join-parent-container {
    background-image: url(./assets/images/join-bg.jpg);
    background-position: top;
    background-size: cover;
    height: 60vh;
    margin-top: 80px;
}

.join-child-container {
    background-color: rgba(255, 215, 0, 0.9); /* Gold with opacity */
    text-align: center;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    top: 200px;
    position: relative;
}

.icons-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.icons-content .image {
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.icons-content .image img {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.join-child-container h4 {
    font-size: 24px;
    font-weight: bold;
}

.icons-content p {
    font-size: 15px;
}

@media (max-width: 1284px) {
    .join-parent-container {
        height: 50vh;
    }

    .join-child-container {
        top: 230px; /* Maintain 50px outside for medium screens */
    }
}

/* @media (max-width: 1024px) and (max-height: 600px) {
    .join-parent-container {
        height: 60vh;
    }

    .join-child-container {
        top: 100px;
    }
} */


@media screen and (max-width: 1024px) and (max-height: 600px) {

    .join-parent-container{
        height: 60vh;
    }
    .join-child-container {
        top: 160px; 
    }
}
  

/* @media (max-width: 1024px) {
    .join-parent-container {
        height: 30vh;
    }

    .join-child-container {
        top: 160px; 
    }
} */

/* Responsive adjustments */
@media (max-width: 768px) {
    .join-parent-container {
        height: 40vh;  /* Increase background height for smaller screens */
        top: 60px;     /* Adjust top positioning */
    }

    .join-child-container {
        padding: 20px 15px; /* Reduced padding with parent padding */
        top: 170px;
        width: 90%;         /* Width adjustment for smaller screens */
    }

    .icons-content .image {
        padding: 8px;  /* Smaller padding for smaller screens */
    }

    .icons-content .image img {
        height: 40px;  /* Smaller icon size */
        width: 40px;
    }

    .icons-content p {
        font-size: 14px; /* Make text a bit smaller for extra small screens */
    }

    .col-12 {
        text-align: center; /* Ensures alignment in smaller columns */
    }
}

@media (max-width: 576px) {
    .join-parent-container {
        height: 50vh;  /* Further increase height for extra small screens */
        top: 40px;
    }

    .join-child-container {
        padding: 15px 10px; /* Smaller padding with parent padding */
        top: 150px; /* Maintain the 50px outside */
        width: 95%;         /* Width adjustment for smaller screens */
    }

    .icons-content .image img {
        height: 35px;  /* Smaller icon size */
        width: 35px;
    }

    .join-child-container h4 {
        font-size: 24px;
    }

    .icons-content p {
        font-size: 10px; /* Make text a bit smaller for extra small screens */
    }
}

@media (max-width: 365px) {
    .join-child-container {
        top: 150px;
    }
}

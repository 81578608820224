/* WorkContainer.css */

.work-container {
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 450px;
  margin: 60px auto;
  padding: 20px;
}

.central-image {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.icon-style {
  position: absolute;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f0f0f0;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
}

.icon-style img {
  width: 40px;
  height: 40px;
}

.icon-text-style {
  margin-top: 8px;
  font-size: 12px;
  color: #333;
  text-align: center;
  white-space: normal;
}

@media (max-width: 768px) {
  .work-container {
    max-width: 300px;
    height: 300px;
    padding: 10px;
  }

  .icon-style {
    width: 100px;
    height: 100px;
  }

  .icon-style img {
    width: 30px;
    height: 30px;
  }

  .icon-text-style {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .work-container {
    max-width: 250px;
    height: 250px;
    padding: 8px;
  }

  .icon-style {
    width: 80px;
    height: 80px;
  }

  .icon-style img {
    width: 25px;
    height: 25px;
    margin-top: -4px;
  }

  .icon-text-style {
    margin-top: 1px;
    font-size: 8px;
    line-height: 1;
  }
}

 /* Container styling */
 

/* Flex styling for larger screens */
.jci-child-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    height: auto;
    background-color: #ffffff;
}

/* Content and Image Styling */
.text-content, .image {
    flex: 1;
}

.text-content {
    display: flex;
    gap: 20px;
    flex-direction: column;

}

.text-content .heading {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.text-content h4 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.text-content p {
    font-size: 20px;
    line-height: 1.5;
    color: #383838;
    width: 100%;
    padding: 0 4px;
}

/* Image alignment */
.image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image img {
    width: 100%;
    max-width: 470px;
    height: auto;

}


/* Styling for the ticker container */
.lower-box {
    width: 100%;
    color: rgb(20, 20, 20);
    font-size: 1.2em;
    padding: 10px 0;
    position: relative;
}


.lower-box span{
    font-weight: 600;
}







/* Responsive adjustments using Bootstrap */
@media (max-width: 768px) {

    .jci-child-container{
        top: -320px;
    }

    .lower-box{
        padding: 0px 0;
    }
    .container-md {
        flex-direction: column;
        text-align: center;
    }

    .text-content h4 {
        font-size: 24px;
    }
    

    .text-content p {
        font-size: 16px;
        line-height: 1.5;
        color: #383838;
        width: 100%;
        padding: 0 4px;
        box-sizing: border-box;
    }

    .image img {
        max-width: 100%;
        margin: 0 auto;
    }
}
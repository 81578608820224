/* Parent container */
.programs-parent-container {
    background-color: #e1e1e1; /* Light background color */
    padding-top: 2rem;
    padding-bottom: 2rem;
}

/* Child container */
.programs-child-container {
    max-width: 1140px; /* Set a max-width for the child container */
    margin: 0 auto; /* Center align the container */
}

/* Section title */
.programs-child-container h3 {
    font-size: 2rem;
    font-weight: 700;
    color: #333;

}

/* Row gap */
.row.two {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

/* Individual program card */
.program-content {
    background-color: #e1e1e1;
    border-radius: 8px;
    padding: 1.5rem;
}

/*!* Hover effect on program card *!*/
/*.program-content:hover {*/
/*    transform: translateY(-5px); !* Slight lift effect *!*/
/*    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); !* Darker shadow on hover *!*/
/*}*/

/* Program image container */
.program-image {
    height: 200px; /* Fix height of the image container */
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
    background-color: #ddd; /* Light gray background if image is loading or missing */
}

/* Program image */
.program-image img {
    object-fit: cover; /* Ensure the image covers the area without distortion */
    width: 100%;
    height: 100%;
}

/* Program details */
h6 {
    font-size: 1rem;
    color: #555;
}

/* Button container */
.viewbtn {
    text-align: center;
    margin-top: 1rem;
}

/* View More button */
.viewbtn .btn-link {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
    border: none;
    padding: 0.5rem 1rem;
    background-color: transparent;
    transition: all 0.3s ease;
}

.viewbtn .btn-link:hover {
    color: #0056b3; /* Darker blue on hover */
    text-decoration: underline;
}

/* Media Queries for responsiveness */

/* For medium-sized screens */
@media (max-width: 992px) {
    .program-content {
        padding: 1rem;
    }

    .program-image {
        height: 150px; /* Adjust image height for medium screens */
    }

    h3 {
        font-size: 1.75rem; /* Slightly smaller title */
    }
}

/* For small-sized screens */
@media (max-width: 576px) {
    .program-content {
        padding: 0.75rem;
    }

    .program-image {
        height: 120px; /* Adjust image height for small screens */
    }

    h3 {
        font-size: 1.5rem; /* Smaller title for mobile */
    }

    h6 {
        font-size: 0.875rem; /* Smaller text for mobile */
    }
}

.profile-card {
    text-align: center;
    height: auto; /* Allow the height to adjust based on content */
    margin: 10px; /* Add some spacing for responsiveness */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.image-frame {
    border: 4px solid rgb(0, 185, 218);
    padding: 10px;
    display: inline-block;
    position: relative;
    top: 50px;
    border-radius: 50%;
}

.image-placeholder {
    width: 200px;
    height: 200px;
    background-color: #808080;
}

.profile-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}

.text-frame {
    background-color: snow;
    height: 160px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 5px 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.200);
    /* border-radius: 12px; */
}

.heading {
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
    color: #161616;
    /* margin-top: 10px; */
}

.paragraph {
    font-size: 18px;
    color: #424242;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .profile-card {
        width: 100%; /* Take full width for smaller screens */
        margin: 10px auto; /* Center cards */
    }

    .image-frame {
        top: 40px; /* Adjust spacing for smaller screens */
    }

    .profile-image,
    .image-placeholder {
        width: 150px;
        height: 150px; /* Smaller images on smaller screens */
    }

    .text-frame { /* Allow text frame to expand based on content */
        padding: 5px;
        height: 140px;
    }

    .heading {
        font-size: 18px; /* Slightly smaller heading */
    }

    .paragraph {
        font-size: 16px; /* Slightly smaller paragraph text */
    }
}

/* Extra small screens (mobile devices) */
@media (max-width: 540px) {
    .profile-card {
        margin: 15px 0; /* Add more vertical spacing for single-column layout */
    }

    .image-frame {
        top: 40px; /* Adjust top margin further */
    }

    .profile-image,
    .image-placeholder {
        width: 120px;
        height: 120px; /* Smaller images for narrow screens */
    }

    .heading {
        font-size: 16px; /* Adjust heading size */
        /* white-space: nowrap; */
    }

    .paragraph {
        font-size: 14px; /* Adjust paragraph text */
    }
}


  .carousel-slide{
    margin-top: 80px;
  }
  /* Carousel Image */
  .carousel-item img {
    object-fit: cover;  /* Ensures the image covers the entire space */
    height: 80vh;  /* Adjust the height to suit your design */
  }
  
  
  /* Carousel Indicators */
  .carousel-indicators button {
    background-color: rgba(255, 255, 255, 0.5); /* Light gray color for inactive indicators */
    border-color: rgba(255, 255, 255, 0.5); /* Border color for inactive indicators */
    height: 50px;
    width: 80px;
  }
  
  .carousel-indicators .active {
    background-color: #fff; /* White color for active indicator */
  }
  


  @media (max-width: 768px) {

    .carousel-item img {
      object-fit: cover;  /* Ensures the image covers the entire space */
      height: 40vh;  /* Adjust the height to suit your design */
    }
    
  }
  
  


  @media (max-width: 395px) {

    .carousel-item img {
      object-fit: cover;  /* Ensures the image covers the entire space */
      height: 40vh;  /* Adjust the height to suit your design */
    }
    
  }
/* YtContainer.css */
  .parent-yt-container {

    background-color: rgb(248, 240, 230);
    display: flex;
    align-items: center;

  }

  .child-yt-container{
    margin-top: 50px;
    margin-bottom: 50px;
  }

  
  
  h4, h5 {
    margin: 0;
  }
  
  h4 {
    font-size: 24px;
    font-weight: bold;
    
  }
  
  
  .yt-videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .card {
    border: none;
  }
  
  .card-img-top {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* Media Query for responsiveness */
  @media (max-width: 768px) {
    .yt-videos {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
      gap: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .yt-videos {
      grid-template-columns: 1fr; /* Single column on very small screens */
    }

    .child-yt-container h4{
      font-size: 24px;
      font-weight: bold;
    }
  }
  
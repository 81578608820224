

.gov-child-container{
    padding-top: 200px;
    background-color: aqua;
}


/* .pimg{
    display: flex;
    justify-content: center;
} */

.img-fluid{

    width: 400px;
    object-position: top;
}
.what-parent-container{
    background-color: #b0edf6;
    display: flex;
    justify-content: center;
    flex-direction: column;

}


.what-child-container{
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}



.what-title-text h4{
  font-size: 24px;
  font-weight: bold;
}


.what-title-text p{
  font-size: 20px;

}


@media (max-width: 395px) {


  

  .what-title-text h4{
    font-size: 24px;
    font-weight: bold;
  }

  .what-title-text p{
    font-size: 15px;
  }
  
}

/* Base styles */
.top-container {
  height: 80px;
  width: 100%;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 1000;
}

.dropdown-item{
  background-color: transparent !important; 
}

/* For specific element */
.dropdown-item:active, .dropdown-item:focus {
  background-color: white;
  outline: none;
}
.nav-link.active {
  background-color: transparent !important; /* Remove background color */
  color: inherit; /* Keep text color */
}


.top-container img {
  max-width: 130px;
}

.top-container:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
.navbar-nav .nav-item a {
  font-size: 1.1rem;
  color: #000000;
}

/* .navbar-nav .nav-item a:hover {
  color: #007bff;
} */





.dropdown-menu {
  background-color: rgb(255, 255, 255);
  border: none;
  display: none;
  opacity: 1;
  visibility: visible;
}

.dropdown-menu.show {
  display: block !important; /* Show dropdown on click in mobile view */
  opacity: 1;
  visibility: visible;
}

.dropdown-menu li{
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}



.navbar-toggler {
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 2em;
}




.side-bar-logo{
  display: none;
  margin-top: 60px;
  margin-bottom: 30px;
  /* height: 80px;
object-fit: cover;
width: 100%; */
}





.social-icons {
  display: flex;
  align-items: center;
}

.social-icons i {
  font-size: 1.3rem;
  color: #474747;
  margin-left: 15px;
}

.social-icons span a{
  background-color: gold;
  padding: 15px 15px;
  white-space: nowrap;
  border-radius: 6px;
  text-decoration: none;
}

.social-icons span a:hover{
  background-color: white;
  color: black;
  border: 3px solid gold;
}

.social-icons i:hover {
  color: #007bff;
}

.sidebar-icons{
  display: none;
}




@media (max-width: 1050px) {

  .nav-item a{
    color: red;
  }
  .navbar-toggler {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  
}


@media (max-width: 1024px) {

  /* .social-icons{
    display: none;
  } */

  .social-icons span a{
    background-color: gold;
    padding: 15px 10px;
  }

  .social-icons i{
    margin-left: 0px;
  }
  
}



/* Mobile view styles */
@media (max-width: 991px) {
  .navbar-toggler {
    outline: none;
    border: none;
    box-shadow: none;
  }

  .top-container img {
    max-width: 110px;
  }
  

  .navbar-collapse {
    position: fixed;
    top: 0;
    left: -250px;
    width: 320px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    transition: right 0.3s ease;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    z-index: 2000;
    color: rgb(28, 28, 28);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .side-bar-logo{
    display: block;
    /* height: 80vh;
    width: 100%;
    object-fit: cover; */
  }

  .navbar-nav .nav-link {
    color: #1e1e1e !important;
    text-align: center;
    width: 100%;
  }

  .navbar-collapse.show {
    left: 0;
  }





  .sidebar-icons {
    display: block;
  } 

  .member-login{
    margin-top: 20px;
  }

  .sidebar-icons .member-login span a{
    background-color: gold;
    padding: 15px 15px;
    white-space: nowrap;
    border-radius: 6px;
    text-decoration: none;
  }
  


  .sidebar-icons .icons-div{
    margin-top: 30px;
    display: flex;
    gap: 20px;
    justify-content: center;


  }

  .sidebar-icons .icons-div a{
    color: rgb(51, 51, 51);
    font-size: large;
    display: block;
    font-size: 1.5em;
  }

  .social-icons {
    display: none;
  }

  .overlay {
    display: none;
  }

  .overlay.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1049;
  }

  /* Make dropdown menus part of document flow in mobile view */
  .dropdown-menu {
    display: none;
  }

  .dropdown-menu li a{
    white-space: wrap;
  }

  .nav-item.dropdown .dropdown-toggle {
    display: inline-block;
  }

  /* Ensure dropdown pushes elements down in mobile */
  .dropdown-menu.show {
    display: block;
  }
}

/* Gallery container styling */
.gallery-parent {
    /* background-color: #ffffff; */
    /* padding: 2rem 0; */
    /* margin-top: 120px; */
    position: relative;
    margin-top: 120px;
    margin-bottom: 100px;
}

.gallery-child h1 {
    text-align: center;
    margin-bottom: 10px;
    color: #333;
}

/* Gallery layout and responsive adjustments */
.gallery-child {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px; /* Space between images */
    justify-content: center;
}

/* Gallery Image Styling */
.gallery-child img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(48, 48, 48, 0.181);
    margin-bottom: 15px; /* Adds space between images */
    background-color: rgb(255, 255, 255);
}

/* Adjust for small screens to add padding between gallery images */
@media (max-width: 540px) {
    .gallery-child img {
        object-fit: cover; /* Maintains aspect ratio without cropping on small screens */
        margin-bottom: 3px; /* Adds more space between images on small screens */
        flex: 1 1 100%; /* 1 image per row */
        max-width: 100%;
        height: 120px;
    }
}

/* Adjust for medium screens (2 images per row) */
@media (max-width: 768px) {

}

/* Lightbox overlay */
.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* padding: 1rem; */
    box-sizing: border-box;
}

/* Lightbox content */
.lightbox-content {
    position: relative;
    max-width: 90%; /* Increased width for smaller screens */
    max-height: 90%;
    margin: 0 auto;
    
    /* overflow: hidden; */
}

.lightbox-content img {
    max-width: 100%;
    max-height: 800px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    object-fit: cover;
}

/* Navigation buttons */
.lightbox-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 2rem;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    border: none;
    outline: none;
}

.lightbox-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.prev-btn {
    left: 20px;
}

.next-btn {
    right: 20px;
}

/* Responsive adjustments for lightbox buttons */
@media (max-width: 768px) {
    .lightbox-content {
        max-width: 95%;
        max-height: 80%;
    }

    .lightbox-btn {
        font-size: 1.5rem;
        width: 40px;
        height: 40px;
    }

    .prev-btn {
        left: 10px;
    }

    .next-btn {
        right: 10px;
    }
}

@media (max-width: 540px) {
    .lightbox-content {
        max-width: 100%;
        max-height: 75%;
    }

    .lightbox-btn {
        font-size: 1.2rem;
        width: 35px;
        height: 35px;
    }

    .prev-btn {
        left: 15px;
    }
    .next-btn {
        right: 15px;
    }
}

.workmanagement-parent {
    background: linear-gradient(to bottom right, #f8f9fa, #e9ecef);
    margin-top: 80px;
}

.head-text {
    font-weight: 700;
    font-size: 2rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    /*color: #20b4e2;*/

}

.table {
    border-radius: 8px;
    overflow: hidden;
}

.table thead th {
    background: #20b4e2;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.table-hover tbody tr:hover {
    background-color: #f1f1f1;
}

.btn-outline-primary:hover,
.btn-outline-secondary:hover,
.btn-outline-info:hover {
    background-color: #007bff;
    color: white;
}

.activity-details {
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}


.work-button{
    border: 2px solid #20b4e2;
    color: #20b4e2;
    font-size: larger;
    padding-left: 30px;
    padding-right: 30px;
    transform: skew(-10deg);

}


.work-button:hover{
    background-color: #20b4e2;
    color: white;
}



.read-more{
    /*border: 2px solid #20b4e2;*/
    border: none;
    color: #20b4e2;
    font-size: larger;
}


.read-more:hover{
    color: #20b4e2;
    text-decoration: underline;
}
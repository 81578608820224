.about-child-container{
    margin-top: 150px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 30px;
}


.group-img-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.group-img-content .grouppng{
    height: 550px;
} 


.group-text-content .mb-4{
    font-size: 20px;

}



.tick-text{
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
}



.tickpng{
    height: 30px;
    width: 30px;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 0;
}


.founder-content{
    margin-top: 120px;
    margin-bottom: 100px;
}


.founderimg{
    display: flex;
    justify-content: start;
}


.founder{
    width: 370px;

}

.founder-text{
    display: flex;
    /* align-items: center; */
    flex-direction: column;
}


.founder-text p{
    font-size: 25px;
}



.founder-detail{
    display: flex;
    align-items: center;
    gap: 20px;
}

.quotepng{
    height: 50px;
}




.detail-text h4{
    font-size: 30px;
}
.detail-text p{
    font-size: 20px;
}


.believe-content{
    margin-bottom: 60px;
}


.believe-content h2{
    font-size: 40px;
}


.believe-title {

    background-color: rgba(27, 118, 118, 0);
}

.believe-title h2 {
    font-size: 100px;
    white-space: nowrap;
}




.believe-text{
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.believe-tick-text{
    display: flex;
    text-align: justify;
    /* font-weight: bold; */
    /* align-items: center; */
    /* justify-content: center; */
}

@media (max-width: 1440px) {

    .about-child-container{
        margin-top: 150px;
    }
    .group-img-content .grouppng{
        height: 300px;
    } 
    
    
}

@media (max-width: 1024px) {

    .founder-text p{
        margin-top: 10px;
        font-size: 25px;
    }
    .detail-text h4{
        font-size: 25px;
    }
    .detail-text p{
        font-size: 15px;
    }

    .believe-title h2 {
        font-size: 80px;
        white-space: nowrap;
    }

    
}


@media (max-width: 1124px) {

    .about-child-container{
        margin-top: 120px;
    }
    /* .group-img-content .grouppng{
        height: 380px;
    }  */
    
    
}


@media (max-width: 1000px) {
    .founderimg{
        justify-content: center;
    }
    .founder-content{
        margin-top: 60px;
    }



    .believe-title h2 {
        font-size: 70px;
        white-space: nowrap;
    }

    /* .founder-text p{
        margin-top: 15px;
        font-size: 30px;
    } */
    
    
}


@media (max-width: 768px) {

    /* .about-child-container{
        margin-top: 600px;
    } */
    /* .about-child-container{
        margin-top: 15%;
    } */
    .group-img-content .grouppng{
        height: 300px;
    } 
    .founder-text p{
        margin-top: 10px;
        font-size: 20px;
    }
    .detail-text h4{
        font-size: 20px;
    }
    .detail-text p{
        font-size: 15px;
    }
    
    
}


@media (max-width: 520px) {

    .group-img-content .grouppng{
        margin-top: 20px;
        height: 350px;
    }
    
}

@media (max-width: 395px) {

    .group-img-content .grouppng{
        height: 350px;
    }

    .founder-content{
        margin-top: 60px;
    }

    .founder-text p{
        margin-top: 15px;
        font-size: 20px;
    }

    .detail-text h4{
        font-size: 22px;
    }


    .believe-title h1 {
        font-size: 50px;
    }
    
}


@media (max-width: 375px) {
    .group-img-content .grouppng{
        height: 300px;
    }
    
}

@media (max-width: 335px) {
    .group-img-content .grouppng{
        height: 300px;
    }
    
}